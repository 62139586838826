body, h1, h2, h3 {font-weight:300;}


.hero {
  background: url('/assets/img/bg-hero.jpg') no-repeat center center;
  background-size: cover;
  height: 100vh;
  margin-bottom: 5rem;
  
  .intro {
    padding: 3rem;
    position: relative; 
    top: 50%;
    transform: translateY(-50%); 
  }

  h1 {
    color: #fff;
    line-height: 1.1;
    text-align: center;
	  font-size:2.5rem;
  }

  p {
    color: #fff;
    line-height: 1.75em;
    text-align: center;
    margin-bottom: 2rem;
  }

  .round-img {
    background: url('/assets/img/round.jpg') no-repeat center center;
    background-size: cover;
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
  }

  @media only screen and (min-width: 768px) {
	  height: 60vh;
    .round-img {
      height: 18rem;
      width: 18rem;
    }
    h1 {
      padding-top: 3.5rem;
      text-align: right;
      font-size: 4.5rem;

    }
    p {
      text-align: right;
    }
  }
	
}
	


hr {margin: 4rem auto;}

blockquote {    display: inline-block;
    margin: 0 auto;
    text-align: left;
    /* width: 60%; */
    font-size: 1.5rem;
    font-style: italic;
    padding: 0 4rem;
    margin-top: 2rem;
	border:none;
}